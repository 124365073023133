.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

a{ cursor: pointer;}

.btn.btn-success:focus,
.btn.btn-success:active,
.btn.btn-success:hover {
  box-shadow: none !important;
}
.wizard-card .tab-content {
  min-height: 0 !important;
}
.card {
  margin-bottom: 10px !important;
}

.parallax {
  /* background-image: url(https://storage.googleapis.com/altamiraresponde-6a16f.appspot.com/imagenes/2584-exactas-uba-la-autoperpetuacion-de-la-campora-a-propuesta-de-la-franja.png); */
  min-height: 75vh;
  max-height: 400px;
  background-attachment: fixed;
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  overflow: hidden;
}
.parallax > .overlay {
  width: 100%;
  height: 100%;
  /* background-color: #22384d; */
  opacity: 0.8;
  position: absolute;
  z-index: 0;
  background-image: url(https://i.imgur.com/U8xIF0w.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.parallax > .content {
  position: absolute;
  top: 5%;
  z-index: 1;
  width: 100%;
}
.parallax h1,
.parallax h2,
.parallax p {
  font-family: "PT Sans", sans-serif;
  color: white;
  width: 100%;
}
.parallax .content > img.isoLogoHome {
  max-width: 10%;
}

.parallax .content > h1 {
  color: white;
  text-align: center;
  font-weight: 900;
  font-size: 5em;
  margin-bottom: 0;
}
.parallax .content > h2 {
  font-size: 2em;
  margin-top: 0;
  margin-bottom: 10px;
  font-weight: 100;
}
.parallax .content > p {
  font-size: 1.3em;
  width: 60%;
  text-align: center;
  margin: 0 auto;
  font-weight: 100;
}
.parallax a.downArrow {
  position: absolute;
  bottom: 10%;
  margin: 0 auto;
  z-index: 1;
  color: #fff;
  font-size: 3em;
}

.card-content {
  float: left;
  min-height: 200px;
  position: relative;
  width: 100%;
}
.card-content.bkg-verde {
  background: rgb(64, 186, 127);
  background: linear-gradient(
    90deg,
    rgba(64, 186, 127, 1) 3%,
    rgba(64, 186, 141, 1) 32%,
    rgba(65, 140, 143, 1) 99%
  );
}
.card-content.bkg-violeta {
  background: rgb(94, 112, 161);
  background: linear-gradient(
    90deg,
    rgba(94, 112, 161, 1) 10%,
    rgba(141, 114, 178, 1) 44%,
    rgba(167, 112, 187, 1) 86%
  );
}
.card-content.bkg-azul {
  background: rgb(43, 51, 101);
  background: linear-gradient(
    90deg,
    rgba(43, 51, 101, 1) 10%,
    rgba(43, 75, 101, 1) 44%,
    rgba(43, 86, 101, 1) 86%
  );
}

.card-content > h3,
.card-content > h4,
.card-content > p {
  text-align: left;
}
.card-content > h3 {
  font-size: 1.6em;
  font-weight: 900;
}

.card-button-home {
  margin-top: 10px;
  float: right;
  position: absolute;
  bottom: 20px;
  right: 20px;
}
.card-button-home > a {
  padding: 5px 20px;
  font-family: "PT Sans", sans-serif;
  font-weight: 700;
  color: #eee;
  font-size: 16px;
}
.card .formBox {
  float: left;
  width: 100%;
}
.card .formBox input[type="text"],
.card .formBox input[type="number"],
.card .formBox input[type="password"]  {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 0;
  color: #000;
  font-family: "PT SANS";
  font-size: 16px;
}
.card .formBox input[type="password"]{
  margin-top: 10px
}

h5.logoutButton,
h5.credencialButton{
  text-align: left;
  float: left;
  width: 100%;
  margin-bottom: 10px;
}
h5.logoutButton > a,
h5.credencialButton > a{
  font-size: 16px;
  padding: 5px 30px;
  background-color: #2b4565;
  margin-bottom: 10px;
  color: white;
  font-weight: 100;
  text-align: left;
  border-radius: 5px;
}
h5.credencialButton > a > i{
  padding-right: 5px;
}
h2.loginTitle{
  margin: 5px 0 0 0;
}
h5.logoutButton,
h2.loginTitle{
  text-align: left;
}


/* QUÉ  ES  EL CECEEEN  */

p.cecen-texto {
  text-align: justify;
  font-size: 1.4em;
  line-height: 1.5em;
  font-weight: 300;
}

/* BECAS */

.about-us .about-services {
  padding-bottom: 20px;
}
.card-content.beca {
  float: left;
  min-height: auto !important;
  position: relative;
  width: 100%;
}

/* LOCALES */
.localBox .imgBox {
  height: 250px;
  background-image: url(https://bibliotecadigital.exactas.uba.ar/sites/exactas/collect/foto/index/assoc/foto_n00/476.dir/foto_n00476_screen.jpeg);
  background-size: cover;
  background-position: center;
}
.localBox .descBox {
  padding: 0 5px 10px 5px;
}
.localBox .descBox h3 {
  font-weight: 900;
  margin-top: 15px;
}
.localBox .opcionesBox {
  float: left;
  width: 100%;
}
.localBox .opcionesBox ul {
  list-style: none;
  float: left;
  width: 100%;
  padding: 0 0 0 5px;
}
.localBox .opcionesBox ul li {
  /* float: left;  */
  padding-bottom: 5px;
}


/* FORMULARIO REGISTRO */
.form-element .form-group{
  margin-top: 10px;
}

/* BOT */
.rsc-ts-bubble{
  font-family: 'PT Sans';
  overflow-wrap: break-word;
}
.rsc-footer{
  display: none;
}
.rsc-cs a{
  float: left!important;
}


/* INFO GENERAL */
#infoContent p{
  text-align: left;
  font-size: 16px;
}
#infoContent h3,
#infoContent ul li{
  text-align: left;
  font-size: 16px;
  font-weight: 900;
  margin-top: 10px;

}
#infoContent ul li{
  font-weight: 100!important;
}
#infoContent h3{
  font-size: 18px!important;
}
#infoContent h4{
  font-size: 18px;
  margin-top:20px;
  text-align: left;
  font-weight: 900;

}
#infoContent h5{
  text-align: left;
  font-weight: 500;
  margin-bottom: 0;
}





/**** MOBILE ****/
@media(max-width:768px){
  .navbar-header{
    text-align: center;
    display: flex;
    justify-content: center;
  }
  .navbar-header > .navbar-toggle{
    display: none;
  }
  .about-services.features-2{
    padding-top: 20px!important
  }
  h5.credencialButton{
    margin-left: 0!important;
  }
}